import React from 'react';
import { useTheme } from 'shared/hooks/useTheme';
import { useBrand } from 'shared/hooks/useBrand';
import { Trans, useTranslation } from 'next-i18next';
import { Product, TranslationKey } from 'shared/constants';

import { EnableClickDisclaimerLine1ForBrands, showDisclaimerLine2, showIconLinks } from 'shared/components/constants';
import { KeyboardKey } from 'shared/types';
import { useBrandConfig } from 'shared/hooks/useBrandConfig';
import parse from 'html-react-parser';
import { useFeature } from 'shared/hooks/useFeature';
import { FeatureToggles } from 'shared/constants/features';

interface Props {
  pageId?: Product;
  logEventHandler: Function;
}

const PageFooter: React.FC<Props> = ({ pageId, logEventHandler }) => {
  const theme = useTheme();
  const brand = useBrand();
  const brandConfig = useBrandConfig()[Product.ALL];

  const showNewLegalDisclaimer =
    useFeature(FeatureToggles.FEATURE_FORCE_LEGAL_UPDATES_TO_SHOW, brand) ||
    ((brandConfig.showNewLegalUpdates || false) && new Date() >= new Date('04/30/2024'));

  const { t: translate } = useTranslation(`${brand}.${TranslationKey.COMMON}`);

  const links = translate('footer.links').split(',');

  const redirectHomePage = (link: string): void => {
    window.open(link, '_blank');
  };

  const handleKeyPress = (ev: React.KeyboardEvent, link: string): void => {
    if (ev.code === KeyboardKey.ENTER) {
      redirectHomePage(link);
    }
  };

  const disclaimerContentKey =
    showNewLegalDisclaimer === true ? 'footer.disclaimer.line1_legal_update' : 'footer.disclaimer.line1';

  return (
    <footer data-test="page-footer">
      <div className="footer__bottom">
        <div className="footer__links">
          {links.join('').length > 0 &&
            links.map((link: string, index: number) => (
              <span
                key={index}
                tabIndex={0}
                onKeyDown={(ev) => handleKeyPress(ev, translate(`footer.links.${link}.href`))}
              >
                <a
                  className="footer__link"
                  href={translate(`footer.links.${link}.href`)}
                  onClick={() => logEventHandler(`/clicked/${link}`)}
                  target="_blank"
                  rel="noopener noreferrer"
                  tabIndex={-1}
                >
                  {translate(`footer.links.${link}.title`)}
                </a>
                {showIconLinks[brand] === true && (
                  <img
                    src={theme.components.pageFooter.footerBottom.links.link.icon}
                    alt={translate(`footer.links.${link}.title`)}
                  />
                )}
              </span>
            ))}
        </div>
        <p className="copy__content--copy" data-test="copy-content-main">
          {translate('footer.copy', { year: new Date().getFullYear() })}
          <br />
          <br />
        </p>
        <p
          onClick={
            EnableClickDisclaimerLine1ForBrands[brand]
              ? () => redirectHomePage(translate('footer.disclaimer.line1.href'))
              : () => {}
          }
          className="copy__content--disclaimer disclaimer--line-1 disclaimer__link"
          data-test="copy-content-disclaimer-line-1"
          tabIndex={EnableClickDisclaimerLine1ForBrands[brand] ? 0 : -1}
          onKeyDown={
            EnableClickDisclaimerLine1ForBrands[brand]
              ? (ev) => handleKeyPress(ev, translate('footer.disclaimer.line1.href'))
              : () => {}
          }
        >
          {showNewLegalDisclaimer === true ? (
            parse(translate(disclaimerContentKey))
          ) : (
            <Trans
              i18nKey="footer.disclaimer.line1"
              ns={`${brand}.${TranslationKey.COMMON}`}
              components={
                brandConfig === undefined || brandConfig.content === undefined
                  ? []
                  : [
                      <a
                        href={brandConfig.content.footer.line1.here}
                        target="_blank"
                        rel="noreferrer"
                        style={{ textDecoration: 'underline' }}
                      >
                        here
                      </a>,
                    ]
              }
            />
          )}
          {showIconLinks[brand] === true && (
            <img
              className="disclaimer--line-img"
              src={theme.components.pageFooter.footerBottom.links.link.icon}
              alt={translate(disclaimerContentKey)}
            />
          )}
        </p>
        {pageId && showNewLegalDisclaimer === false && showDisclaimerLine2[pageId] && (
          <p className="copy__content--disclaimer disclaimer--line-2" data-test="copy-content-disclaimer-line-2">
            {translate('footer.disclaimer.line2')}
          </p>
        )}
      </div>

      <style jsx>
        {`
          footer {
            display: flex;
            flex-flow: column nowrap;
            font-family: '${theme.components.pageFooter.font.family}';
            color: ${theme.components.pageFooter.background.color};
            padding-top: ${theme.components.footer.padding.top};
            padding-right: 0;
            padding-bottom: 0;
            padding-left: 0;
          }

          .footer__bottom {
            display: flex;
            flex-direction: column;
            font-family: '${theme.components.pageFooter.copy.font.family}';
            border-top: ${theme.components.pageFooter.border.top};
            padding-right: ${theme.components.pageFooter.padding.right.mobile};
            padding-left: ${theme.components.pageFooter.padding.left.mobile};
            padding-top: ${theme.components.pageFooter.padding.top.mobile};
            padding-bottom: ${theme.components.pageFooter.padding.bottom.mobile};
            color: ${theme.components.pageFooter.font.color};
            background-color: ${theme.components.pageFooter.background.color};
            font-size: ${theme.components.pageFooter.font.size};
          }

          .footer__links {
            display: flex;
            justify-content: ${theme.components.pageFooter.footerBottom.links.justifyContent};
            padding-left: ${theme.components.pageFooter.footerBottom.links.padding.left};
            padding-right: ${theme.components.pageFooter.footerBottom.links.padding.right};
            padding-bottom: ${theme.components.pageFooter.footerBottom.links.padding.bottom};
            order: ${theme.components.pageFooter.footerBottom.links.order.mobile};
            margin-bottom: ${theme.components.pageFooter.footerBottom.links.marginBottom};
            flex-wrap: wrap;
          }

          .footer__link {
            padding: 3px 4px 4px 0;
            margin-top: ${theme.components.pageFooter.footerBottom.links.link.margin.top};
            margin-right: ${theme.components.pageFooter.footerBottom.links.link.margin.right};
            margin-bottom: ${theme.components.pageFooter.footerBottom.links.link.margin.bottom};
            margin-left: ${theme.components.pageFooter.footerBottom.links.link.margin.left};
            color: ${theme.components.pageFooter.font.color};
            font-family: '${theme.components.pageFooter.font.family}';
          }

          .disclaimer--line-1 {
            order: ${theme.components.pageFooter.footerBottom.disclaimerLine1.order.mobile};
          }

          .disclaimer--line-img {
            padding-left: ${theme.components.pageFooter.footerBottom.disclaimerLine1.img.padding.left};
          }

          .copy__content--copy,
          .copy__content--disclaimer {
            text-align: ${theme.components.pageFooter.copy.textAlign};
            color: ${theme.components.pageFooter.copy.font.color};
            line-height: ${theme.components.pageFooter.footerBottom.disclaimer.lineHeight};
            font-family: ${theme.components.pageFooter.font.family};
            padding-bottom: ${theme.components.pageFooter.copy.padding.bottom};
          }

          .copy__content--copy {
            color: ${theme.components.pageFooter.footerBottom.disclaimerLine1.color};
          }

          .disclaimer__link {
            height: fit-content;
            text-decoration: ${theme.components.pageFooter.footerBottom.links.link.textDecoration};
            cursor: ${theme.components.pageFooter.footerBottom.links.link.cursor};
          }

          .disclaimer__link::before {
            content: '${theme.components.form.disclaimer.before.content}';
            text-decoration: ${theme.components.form.disclaimer.before.textDecoration};
            text-decoration-color: ${theme.components.form.disclaimer.before.textDecorationColor};
          }

          .copy__content--copy {
            order: ${theme.components.pageFooter.footerBottom.copy.order};
          }

          .disclaimer--line-2 {
            order: ${theme.components.pageFooter.footerBottom.disclaimerLine2.order};
          }

          .footer__links img {
            padding-right: ${theme.components.pageFooter.footerBottom.links.img.padding.right};
          }

          .footer__links span {
            margin-bottom: ${theme.components.pageFooter.footerBottom.links.span.margin.bottom};
          }

          .footer__link {
            text-decoration: ${theme.components.pageFooter.footerBottom.links.link.textDecoration};
            color: ${theme.components.pageFooter.footerBottom.links.color};
          }

          .footer__links span:first-of-type:before {
            content: '';
            margin: 0px;
          }

          .footer__links span:before {
            content: '${theme.components.pageFooter.footerBottom.links.separator.content}';
            margin: ${theme.components.pageFooter.footerBottom.links.separator.spacing.mobile};
          }

          .copy__content--copy {
            border-width: ${theme.components.pageFooter.copy.border.width};
            border-style: ${theme.components.pageFooter.copy.border.style};
            border-color: ${theme.components.pageFooter.copy.border.color};
            padding-top: ${theme.components.pageFooter.copy.padding.top};
            width: ${theme.components.pageFooter.copy.width};
          }

          .disclaimer--line-2 {
            margin-bottom: ${theme.components.pageFooter.footerBottom.disclaimerLine2.marginBottom.mobile};
          }

          @media screen and (min-width: ${theme.viewports.desktop}) {
            .footer__bottom {
              padding-bottom: ${theme.components.pageFooter.padding.bottom.tablet};
              flex-direction: ${theme.components.pageFooter.footerBottom.flex.direction};
              flex-wrap: ${theme.components.pageFooter.footerBottom.flex.wrap};
              padding-left: ${theme.components.pageFooter.padding.left.tablet};
              padding-right: ${theme.components.pageFooter.padding.right.tablet};
            }

            .footer__links {
              width: 100%;
              order: ${theme.components.pageFooter.footerBottom.links.order.tablet};
            }

            .footer__links span:before {
              margin: ${theme.components.pageFooter.footerBottom.links.separator.spacing.desktop};
            }

            .disclaimer--line-1 {
              order: ${theme.components.pageFooter.footerBottom.disclaimerLine1.order.tablet};
            }

            .disclaimer--line-2 {
              margin-bottom: ${theme.components.pageFooter.footerBottom.disclaimerLine2.marginBottom.desktop};
            }

            .copy__content--copy {
              border-width: ${theme.components.pageFooter.copy.border.width};
              border-style: ${theme.components.pageFooter.copy.border.style};
              border-color: ${theme.components.pageFooter.copy.border.color};
              padding-top: ${theme.components.pageFooter.copy.padding.top};
            }

            .copy__content--disclaimer {
              margin-left: ${theme.components.pageFooter.copy.margin.left};
            }
          }
        `}
      </style>
    </footer>
  );
};

export { PageFooter as default };
